<template>
    <FormRDStation id="wpp-formulario-orcamento-loja-fisica-0609aab5b67505796081" code="UA-174539066-1"></FormRDStation>
</template>

<script>
import FormRDStation from '@/components/FormRDStation';
export default {
    components: {
        FormRDStation
    }
}
</script>