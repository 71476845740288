<template>
    <div class="home-btn-gmzmoke">
       
        <h2 class="mb-4 text-center ">Qual é o seu modelo de negócio?</h2>
        <div class="buttons btn-whats-gmzmoke">
            
            <button class="btn btn-success w-100 mb-2" @click="exibirTela('Lojafisica')">
                Loja Física
            </button>

            <button class="btn btn-success w-100 mb-2" @click="exibirTela('Ecommerce')">
                E-commerce
            </button>

            <button class="btn btn-success w-100 mb-2" @click="exibirTela('Outros')">
                Outros
            </button>

            <p class="nodropshipping"><span>Atenção:</span> Não oferecemos produtos para dropshipping.</p>

        </div>

    </div>
</template>

<script>
export default {
    methods: {
        exibirTela(tela) {
            this.$emit('mudarTela', tela);
        }
    }
}
</script>