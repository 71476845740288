<template>
    <div>
        <div class="gmz-whatsapp-widget" @click="emitClickEvent">
            <img src="./../assets/whatsapp-icon.svg" alt="Fale Conosco">
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        emitClickEvent() {
            this.$emit('clicked');
        }
    }
}
</script>

<style scope>
@keyframes vibrate {
    0%,
    2%,
    4%,
    6%,
    8%,
    10%,
    12%,
    14%,
    16%,
    18% {
        -webkit-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
    }

    1%,
    3%,
    5%,
    7%,
    9%,
    11%,
    13%,
    15%,
    17%,
    19% {
        -webkit-transform: translate3d(1px, 0, 0);
        transform: translate3d(1px, 0, 0);
    }

    20%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.gmz-whatsapp-widget {
    box-sizing: border-box;
    position: fixed;
    right: var(--gmz-whatsapp-widget-right, 20px);
    left: var(--gmz-whatsapp-widget-right, auto);
    bottom: var(--gmz-whatsapp-widget-bottom, 20px);
    width: var(--gmz-whatsapp-widget-widget, 65px);
    height: var(--gmz-whatsapp-widget-height, 65px);
    background-color: var(--gmz-whatsapp-widget-background-color, #04AA6D);
    padding: var(--gmz-whatsapp-widget-padding, 15px);
    border-radius: var(--gmz-whatsapp-widget-border-radius, 50%);
    cursor: pointer;
    transition: .3s ease-in-out;
    transition-property: background-color;
}
.gmz-whatsapp-widget:not(:hover) {
    
    -webkit-animation: vibrate 2s cubic-bezier(.36, .07, .19, .97) infinite;
    animation: vibrate 2s cubic-bezier(.36, .07, .19, .97) infinite;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 300px;
    perspective: 300px;
}

.gmz-whatsapp-widget:hover {
    background-color: var(--gmz-whatsapp-widget-hover-background-color, #009744);
}

.gmz-whatsapp-widget img {
    width: 100%;
    height: auto;
    fill: #fff;

}

.nodropshipping {
    font-weight: bold;
    text-transform: uppercase;
    font-family: sans-serif;
    color: red;
}

</style>